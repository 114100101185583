import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { accountsService } from '@/Accounts/accountsServices';
import { ColumnSortDef } from '@/shared/components';
import {
  TeamWithProjectIdsSchema,
  TeamWithProjectsSchema,
} from '@/Accounts/types';
import { RootStore } from '@/stores/rootStore';
import { EditTeamsStore } from './editTeamStore';
import keyBy from 'lodash/keyBy';
import { TeamSchema } from '../../types';

export class TeamsStore {
  root: RootStore;
  editTeamStore: EditTeamsStore;
  inited = false;
  loading = 0;
  disposers: Array<() => void> = [];
  teams: Array<TeamWithProjectIdsSchema> = [];
  teamDetailsMap: { [teamId: number]: TeamWithProjectsSchema } = {};
  sortBy: ColumnSortDef = { id: 'name', direction: 'asc' };
  filterText = '';

  constructor(root: RootStore) {
    this.root = root;
    this.editTeamStore = new EditTeamsStore(root);
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  get teamsMap(): { [id: number]: TeamWithProjectIdsSchema } {
    return keyBy(this.teams, 'id');
  }

  init() {
    if (!this.inited) {
      this.inited = true;
      this.loading = 0;
      // react to url changes by updating model data and loading account data
      this.disposers.push(
        reaction(
          () => this.root.accountsStore.accountsUrlParams?.accountId || this.root.accountsStore.accountId,
          (accountId) => {
            if (accountId) {
              this.loadTeams();
            }
          },
          { fireImmediately: true },
        ),
      );
    }
    this.editTeamStore.init();
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  incrementLoading() {
    ++this.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.loading;
    this.root.decrementLoading();
  }

  setSortBy(sortBy: ColumnSortDef) {
    this.sortBy = sortBy;
  }

  setTeams(val: Array<TeamWithProjectIdsSchema>) {
    this.teams = val as Array<Required<TeamWithProjectIdsSchema>>;
  }

  setFilterText(value: string) {
    this.filterText = value;
  }

  get displayRows(): Array<TeamSchema> {
    const lcFilter = this.filterText.toLowerCase();
    return this.teams
      .filter(team => {
        return (!lcFilter) || `${team.name}`.toLowerCase().includes(lcFilter);
      });
  }

  refreshTeamMembership(teamId: number) {
    accountsService.getTeamDetails(teamId).then((response) => {
      runInAction(() => {
        this.teamDetailsMap[teamId] = response.data;
        // force an update so that membership counts are properly updated on the team index page
        this.setTeams([...this.teams]);
      });
    });
  }

  getVaultCountForTeam(team: TeamWithProjectIdsSchema) {
    const vaultSet = new Set<number>();
    const {
      accountsStore: {
        vaultsAndProjectsStore: { projectsIdToVaultIdMap },
      },
    } = this.root;
    team.project_ids.forEach((projectId) => {
      vaultSet.add(projectsIdToVaultIdMap[projectId]);
    });
    return vaultSet.size;
  }

  getUserCountForTeam(team: TeamWithProjectIdsSchema) {
    const userSet = new Set<number>();
    const details = this.teamDetailsMap[team.id];
    details?.team_memberships.forEach((membership) => {
      if (membership.team_id === team.id) {
        userSet.add(membership.user_id);
      }
    });
    return userSet.size;
  }

  async loadTeams() {
    try {
      this.incrementLoading();
      const response = await accountsService.getTeamsWithProjectIds();
      this.setTeams(response.data);
      this.teams.forEach((team) => {
        this.refreshTeamMembership(team.id);
      });
    } finally {
      this.decrementLoading();
    }
  }
}
