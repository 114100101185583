import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { ColumnSortDef } from '@/shared/components';
import {
  ProjectSchema,
  RoleName,
  VaultUserWithPermissions,
  VaultWithProjectsSchema,
} from '@/Accounts/types';
import { accountsService } from '@/Accounts/accountsServices';
import keyBy from 'lodash/keyBy';
import { EditProjectStore } from './editProjectStore';

export class VaultsAndProjectsStore {
  inited = false;
  loading = 0;
  filterText = '';

  editProjectStore: EditProjectStore;
  userSelectedVaultId: number | null = null;

  disposers: Array<() => void> = [];

  vaults: Array<VaultWithProjectsSchema> = [];
  usersInSelectedVault: Array<VaultUserWithPermissions> = [];

  sortBy: ColumnSortDef = { id: 'name', direction: 'asc' };

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.editProjectStore = new EditProjectStore(root);
  }

  init() {
    if (!this.inited) {
      this.inited = true;
      // react to url changes by updating model data and loading account data
      this.disposers.push(
        reaction(
          () => this.root.accountsStore.accountsUrlParams?.accountId,
          (accountId) => {
            if (accountId) {
              this.loadVaults();
            }
          },
          { fireImmediately: true },
        ),
      );

      // load a single vault based on the id
      this.disposers.push(
        reaction(
          () => this.vaultsProjectsSettingsUrlParams?.vaultId,
          (vaultId) => {
            if (vaultId) {
              this.loadVault(vaultId);
            }
          },
          { fireImmediately: true },
        ),
      );

      // load tne users for the selected vault
      this.disposers.push(
        reaction(
          () => this.selectedVault,
          (selectedVault) => { // eslint-disable-line @typescript-eslint/no-unused-vars
            this.refreshVaultUsers();
          },
          { fireImmediately: true },
        ),
      );
    }
    this.editProjectStore.init();
  }

  refreshVaultUsers() {
    if (this.selectedVault) {
      accountsService
        .getVaultUsersWithPermissions(this.selectedVault.id)
        .then((response) => {
          runInAction(() => {
            this.usersInSelectedVault = response.data;
          });
        });
    }
  }

  setFilterText(value: string) {
    this.filterText = value;
  }

  /**
   * Return an extracted set of url params if we're on the Settings > Vault page
   */
  get vaultsProjectsSettingsUrlParams() {
    const { routerStore } = this.root;
    const protocolsParams = routerStore.extractFromPattern(
      '/vaults/:vaultId/projects',
    );
    if (protocolsParams) {
      return {
        vaultId: parseInt('' + protocolsParams.vaultId, 10) as
          | number
          | undefined,
      };
    }
    return null;
  }

  get showVaultSelection() {
    return this.vaults.length > 1;
  }

  get currentUserIsVaultAdmin() {
    // if the current user has vault
    let result = false;
    if (this.selectedVault) {
      this.usersInSelectedVault.forEach(user => {
        if (user.email === this.root.environmentStore.currentUser.email) {
          const role_name = user.vault_permissions?.find(perm => perm.vault_id === this.selectedVault.id)?.role_name;
          if (role_name === 'vault admin') {
            result = true;
          }
        }
      });
    }
    return result;
  }

  get currentUserHasProjectMemberships() {
    const { usersMap } = this.root.accountsStore.usersStore;
    const currentUser = usersMap[this.root.environmentStore.currentUser.id];
    const numberOfProjectsForCurrentVault = currentUser?.project_permissions
      ?.filter((pm) => pm.vault_id == (this?.selectedVault?.id ?? 0))?.length;
    return !!numberOfProjectsForCurrentVault;
  }

  get displayRows(): Array<ProjectSchema> {
    const lcFilter = this.filterText.toLowerCase();
    return this.projectsForSelectedVault
      .filter(project => {
        return (!lcFilter) || project.name.toLowerCase().includes(lcFilter);
      });
  }

  get projectsForSelectedVault(): Array<ProjectSchema> {
    return (
      this.selectedVault?.projects.map((project) => ({
        ...project,
      })) ?? []
    );
  }

  get projects(): Array<ProjectSchema> {
    const result = [];
    this.vaults.forEach(vault => {
      result.push(...vault.projects);
    });
    return result;
  }

  get vaultsMap(): { [id: number]: VaultWithProjectsSchema } {
    return keyBy(this.vaults, 'id');
  }

  get projectsForSelectedVaultMap(): { [id: number]: ProjectSchema } {
    return keyBy(this.projectsForSelectedVault, 'id');
  }

  get projectsMap(): { [id: number]: ProjectSchema } {
    return keyBy(this.projects, 'id');
  }

  get projectsIdToVaultIdMap(): { [projectId: number]: number } {
    const result = {};
    this.vaults.forEach((vault) => {
      (vault.projects ?? []).forEach((project) => {
        result[project.id] = vault.id;
      });
    });
    return result;
  }

  get selectedVaultId() {
    return (
      this.userSelectedVaultId ??
      this.root.accountsStore.vaultId ??
      this.vaultsProjectsSettingsUrlParams?.vaultId ??
      (this.vaults.length ? this.vaults[0].id : undefined)
    );
  }

  get userRoleMap() {
    const result: { [userId: number]: RoleName } = {};

    this.usersInSelectedVault.forEach(userWithPerm => {
      userWithPerm.vault_permissions.forEach(perm => {
        if (perm.vault_id === this.selectedVault.id) {
          result[userWithPerm.id] = perm.role_name;
        }
      });
    });
    return result;
  }

  get selectedVault(): VaultWithProjectsSchema {
    const { selectedVaultId } = this;

    if (selectedVaultId) {
      return (
        this.vaults.find((vault) => '' + vault.id === '' + selectedVaultId) ??
        null
      );
    }
    return null;
  }

  setSelectedVault(vault: VaultWithProjectsSchema) {
    // go to new url with the vault specified (reloading the page)
    window.location.href = `${location.origin}/vaults/${vault.id}/accounts/${this.root.accountsStore.accountId}#projects`;
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  incrementLoading() {
    ++this.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.loading;
    this.root.decrementLoading();
  }

  setSortBy(value: ColumnSortDef) {
    this.sortBy = value;
  }

  async loadProjectsForVault() {
    if (this.selectedVault) {
      const response = await accountsService.getVaultDetails(
        this.selectedVault.id,
      );
      Object.assign(this.selectedVault, response.data);
    }
  }

  private async loadVaults() {
    const { accountId } = this.root.accountsStore;

    this.setFilterText('');
    if (accountId) {
      try {
        this.incrementLoading();

        const response = await accountsService.getVaultsWithProjects();
        runInAction(() => {
          this.vaults = response.data;
        });
      } finally {
        this.decrementLoading();
      }
    }
  }

  private async loadVault(vaultId: number) {
    try {
      this.incrementLoading();
      const response = await accountsService.getVaultDetails(vaultId);
      runInAction(() => {
        this.root.accountsStore.accountForVaultId = response.data.account_id;
        this.vaults = [response.data];
      });
    } finally {
      this.decrementLoading();
    }
  }
}
