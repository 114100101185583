import { deepClone } from '@/Annotator/data/utils';
import { cancelBroadcastError } from '@/ErrorReporting/subscribe';
import { buildFieldDefinitions } from '@/Inventory/components/buildFieldDefinitions';
import { QueryFilter } from '@/Inventory/models/QueryFilter';
import { InventoryFieldUniqueKey } from '@/Protocols/types';
import { LocationUtils } from '@/Samples/components/location/locationUtils';
import { InventorySampleService } from '@/Samples/inventorySampleService';
import type {
  EditInventoryEvent,
  EditSample,
  InventoryEvent,
  LocationNode,
  Sample,
  TSearchQuery,
} from '@/Samples/types';
import {
  GetAutocompleteOptions,
  TInventoryFilter,
} from '@/Search/SearchFilters/FilterValueSelect.types';
import { ColumnSortDef } from '@/shared/components';
import {
  MessageType,
  TGlobalMessage,
} from '@/shared/components/GlobalMessage/GlobalMessage';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { compressForParam } from '@/shared/utils/moleculeImageHelper';
import { translateCaseInsensitive } from '@/shared/utils/stringUtils';
import { RootStore } from '@/stores/rootStore';
import { VaultPreferences } from '@/stores/vaultPreferencesStore';
import { KeysToCamelCase, shallowCamelCaseKeys } from '@/typeCasings';
import { CDD } from '@/typedJS';
import axios, { AxiosResponse } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { InventoryExportOptions } from '../InventoryExportDialog';
import type {
  CustomOrDefaultResultColumnId,
  FieldType,
  FieldTypeMap,
  FlattenedInventoryEvent,
  InventoryAutocompleteRequestProps,
  InventoryAutocompleteResponseProps,
  InventoryResultColumn,
  InventorySearchViewAPIProps,
  InventorySearchViewInitialLoadProps,
  InventorySearchViewJsonAPIProps,
  LocationAncestryRequestProps,
  LocationAncestryResponseProps,
  NestedInventoryResultColumn,
  ProcessedInventoryEntries,
  TSearchHighlight,
} from '../components/types';
import { InventoryStoreHelper } from './InventoryStoreHelper';

// 'totalNumChars' includes length of ellipses
const ellipsizeString = (fullLenString: string, totalNumChars: number) => {
  if (fullLenString.length <= totalNumChars) {
    return fullLenString;
  }
  const ellipses = '...';
  return `${fullLenString.slice(
    0,
    totalNumChars - ellipses.length,
  )}${ellipses}`;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EmptyFieldTypeMap: FieldTypeMap<any> = {
  Event: [],
  Molecule: [],
  Sample: [],
} as const;

type TSearchError = { error_messages: string[] };

export class InventoryStore {
  disposers: Array<() => void> = [];
  inited = false;
  // TODO refactor to not use this
  setup = false;
  loadingCount = 0;
  searchPage = 0;

  dataProps: InventorySearchViewAPIProps | null = null;
  inventory_field_definitions: InventorySearchViewAPIProps['custom_field_definitions'] =
    { ...EmptyFieldTypeMap };

  available_filters: InventorySearchViewAPIProps['available_filters'] = {
    ...EmptyFieldTypeMap,
  };

  custom_field_definitions: InventorySearchViewAPIProps['custom_field_definitions'] =
    { ...EmptyFieldTypeMap };

  all_units: InventorySearchViewAPIProps['all_units'];
  user_structure_masked: InventorySearchViewAPIProps['user_structure_masked'];
  vault_name_map: InventorySearchViewAPIProps['vault_name_map'];
  search_inventory_entries_url: InventorySearchViewInitialLoadProps['links']['inventory_entries_url'];
  inventory_entries_export_url: InventorySearchViewInitialLoadProps['links']['inventory_entries_export_url'];
  available_result_columns: NestedInventoryResultColumn[] = [];
  inventory_entries_export_progress_url: InventorySearchViewInitialLoadProps['links']['inventory_entries_export_progress_url'];
  inventory_search_autocomplete_urls: {
    custom: InventorySearchViewInitialLoadProps['links']['inventory_search_custom_autocomplete_url'];
    default: InventorySearchViewInitialLoadProps['links']['inventory_search_default_autocomplete_url'];
  };

  sampleLimitHit = false;

  totalCount: InventorySearchViewAPIProps['total_count'] = -1;
  inventoryEntries: ProcessedInventoryEntries = [];
  searchHighlights: TSearchHighlight = {};

  queryFilters: (TSearchQuery['query_filters'][number] | null)[] = [];
  queryText: TSearchQuery['text'] = null;
  queryMRV: TSearchQuery['mrv'] = null;

  locationsHash: Record<number, Array<LocationNode>> = {};

  exportDialogOpen = false;

  errors: ({ id: number } & TGlobalMessage)[] = [];

  get hasErrors() {
    return this.errors.length > 0;
  }

  // anything marked `must be unique` - has special filter
  get inventory_id_list_fd_ids(): FieldTypeMap<number[]> {
    return Object.fromEntries(
      Object.entries(this.custom_field_definitions).map(([key, values]) => [
        key,
        values.filter((val) => val.unique_value).map((val) => val.id),
      ]),
    ) as FieldTypeMap<number[]>;
  }

  private async sendSearchQuery(): Promise<
    KeysToCamelCase<InventorySearchViewJsonAPIProps>
    > {
    try {
      const retrievedData = await axios.post<
        InventorySearchViewJsonAPIProps | TSearchError
      >(this.search_inventory_entries_url, {
        ...this.serializedSearchQuery,
        page: this.searchPage,
      });
      if ('error_messages' in retrievedData.data) {
        retrievedData.data.error_messages.forEach((error_message) => {
          this.handleError(error_message);
        });
        return;
      }
      return shallowCamelCaseKeys(retrievedData.data);
    } catch (error) {
      this.handleError(error);
    }
  }

  init() {
    if (this.inited) {
      return;
    }
    this.inited = true;
  }

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  get inventoryColumnPrefs() {
    return { ...this.root.vaultPreferencesStore.inventoryColumnPrefs };
  }

  updatePreferences(newPrefs: Partial<VaultPreferences>) {
    this.root.vaultPreferencesStore.updatePreferences(newPrefs);
  }

  setPreferences(newPrefs: VaultPreferences['inventoryColumnPrefs']) {
    this.root.vaultPreferencesStore.setPreference('inventoryColumnPrefs', {
      ...this.inventoryColumnPrefs,
      ...newPrefs,
    });
  }

  get searchQuery(): TSearchQuery {
    return {
      text: this.queryText,
      query_filters: this.queryFilters,
      mrv: this.queryMRV,
    };
  }

  get serializedSearchQuery() {
    return {
      text: this.queryText === null ? '' : this.queryText,
      query_filters:
        this.queryFilters.length === 0
          ? undefined
          : this.queryFilters.map((val) => val.serialize()),
      mrv: this.queryMRV === null ? undefined : compressForParam(this.queryMRV),
    };
  }

  get vaultId() {
    const { vaultId } = this.root.routerStore.extractFromPattern(
      '/vaults/:vaultId',
    ) ?? { vaultId: null };
    return vaultId as number;
  }

  async getLocationAncestryString({
    location_id,
    fd_id,
  }: LocationAncestryRequestProps): Promise<LocationAncestryResponseProps> {
    let ancestry_hash: Record<number, string> = {};
    try {
      const url =
        this.root.locationStore.locationAncestryPathForFieldDef(fd_id);
      this.incrementLoadingCount();
      const result = await axios.post<{
        ancestry_hash: Record<number, string>;
      }>(url, { ids: [location_id] });
      ancestry_hash = result.data.ancestry_hash;
    } finally {
      this.decrementLoadingCount();
    }
    return ancestry_hash;
  }

  async getInventoryLocations({
    fd_id,
  }: {
    fd_id: number;
  }): Promise<LocationNode[]> {
    const existingLocations = this.locationsHash[fd_id] || [];
    if (existingLocations.length === 0) {
      const locations = await this.loadLocations(fd_id);
      return locations;
    }
    return existingLocations;
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  private async loadLocations(location_fd_id: number) {
    try {
      const url =
        this.root.locationStore.locationJsonPathForFieldDef(location_fd_id);
      this.incrementLoadingCount();
      const result = await axios.get<{
        inventory_locations: Array<LocationNode>;
      }>(url);
      runInAction(() => {
        LocationUtils.sortByDisplayOrder(result.data.inventory_locations);
        this.locationsHash[location_fd_id] = result.data.inventory_locations;
      });
      return result.data.inventory_locations;
    } finally {
      this.decrementLoadingCount();
      if (!this.inited) {
        runInAction(() => {
          this.inited = true;
        });
      }
    }
  }

  async resetInventoryPreferences() {
    this.root.vaultPreferencesStore.setPreference(
      'inventoryColumnPrefs',
      undefined,
    );
    this.resetSearchQuery();
    await this.submitQuery();
  }

  incrementLoadingCount() {
    ++this.loadingCount;
  }

  decrementLoadingCount() {
    --this.loadingCount;
  }

  get loading() {
    return this.loadingCount > 0;
  }

  async setSortBy(value: ColumnSortDef<CustomOrDefaultResultColumnId>) {
    this.updatePreferences({
      inventoryColumnPrefs: {
        sortedColumn: value,
      },
    });
    await this.submitNewQuery();
  }

  get availableColumns(): NestedInventoryResultColumn[] {
    return this.available_result_columns.map((parent) => ({
      ...parent,
      label: translateCaseInsensitive(parent.label, true),
      children: parent.children.map((child) => ({
        ...child,
        name: translateCaseInsensitive(child.name, true),
      })),
    }));
  }

  get sortedColumn() {
    return this.inventoryColumnPrefs
      .sortedColumn as ColumnSortDef<CustomOrDefaultResultColumnId>;
  }

  get visibleColumns(): InventoryResultColumn[] {
    if (this.inventoryFieldDefinitions.all.length === 0) {
      return [];
    }
    const result = this.inventoryColumnPrefs.visibleColumnIds
      .map(({ id, field_type }) => {
        const foundDef =
          this.inventoryFieldDefinitions.byFieldThenSelectValue[field_type][id];
        return { id, label: foundDef.name, name: foundDef.name };
      })
      .filter((def) => !!def);

    const sortedColumn = this.sortedColumn;

    return result.map((column) => {
      if (`${column?.id}` == `${sortedColumn.id}`) {
        return {
          ...column,
          id: column.id as CustomOrDefaultResultColumnId,
          direction: sortedColumn.direction,
        };
      }
      return { ...column, id: column.id as CustomOrDefaultResultColumnId };
    });
  }

  setVisibleColumnDetails(details: Array<InventoryFieldUniqueKey>) {
    this.root.vaultPreferencesStore.setPreference('inventoryColumnPrefs', {
      visibleColumnIds: details,
    });
  }

  async handleToggleDeplete(entry: FlattenedInventoryEvent) {
    if (!(await ModalUtils.confirmDepleteSample(entry.name))) {
      return;
    }
    const sample = InventoryStoreHelper.convertToInventorySample(
      entry,
      this.inventory_event_field_definitions,
    );
    sample.depleted = !sample.depleted;
    InventorySampleService.handleSubmitEditSample({
      sample,
      moleculeId: entry.batch.molecule_id,
      on409Error: async () => {
        this.handleError('409 Error');
      },
      onGenericError: async (error) => {
        this.handleError(`Request Error: ${error}`);
      },
      onSubmitSuccess: () => {
        this.submitQuery();
      },
    });
  }

  get inventory_event_field_definitions() {
    return this.inventory_field_definitions.Event;
  }

  get inventory_sample_field_definitions() {
    return this.inventory_field_definitions.Sample;
  }

  setupInventoryURLs({
    links: {
      inventory_entries_url,
      inventory_entries_export_progress_url,
      inventory_entries_export_url,
      inventory_search_default_autocomplete_url,
      inventory_search_custom_autocomplete_url,
    },
  }: InventorySearchViewInitialLoadProps): void {
    this.search_inventory_entries_url = inventory_entries_url;
    this.inventory_entries_export_url = inventory_entries_export_url;
    this.inventory_entries_export_progress_url =
      inventory_entries_export_progress_url;
    this.inventory_search_autocomplete_urls = {
      custom: inventory_search_custom_autocomplete_url,
      default: inventory_search_default_autocomplete_url,
    };
  }

  async getInitialProps(): Promise<InventorySearchViewAPIProps> {
    this.incrementLoadingCount();
    try {
      const retrievedData = await axios.get<
        InventorySearchViewAPIProps | TSearchError
      >(this.search_inventory_entries_url);

      runInAction(() => {
        this.setup = true;
      });
      if ('error_messages' in retrievedData.data) {
        retrievedData.data.error_messages.forEach((error_message) => {
          this.handleError(error_message);
        });
        return;
      }
      return retrievedData.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  getAutocompleteOptions: GetAutocompleteOptions = async (props) => {
    this.incrementLoadingCount();
    try {
      const url = Number.isInteger(props.select_value)
        ? this.inventory_search_autocomplete_urls.custom
        : this.inventory_search_autocomplete_urls.default;
      const retrievedData = await axios.post<
        InventoryAutocompleteResponseProps,
        AxiosResponse<InventoryAutocompleteResponseProps>,
        InventoryAutocompleteRequestProps
      >(url, props);

      return retrievedData.data;
    } catch {
      return { suggestions: [] };
    } finally {
      this.decrementLoadingCount();
    }
  };

  setupInventorySearchPage({
    inventory_field_definitions,
    available_filters,
    available_result_columns,
    initial_entries,
    saved_filters,
    total_count,
    custom_field_definitions,
    all_units,
    user_structure_masked,
    vault_name_map,
  }: Pick<
    InventorySearchViewAPIProps,
    | 'available_result_columns'
    | 'initial_entries'
    | 'saved_filters'
    | 'total_count'
    | 'available_filters'
    | 'custom_field_definitions'
    | 'all_units'
    | 'user_structure_masked'
    | 'vault_name_map'
  > & {
    inventory_field_definitions: InventorySearchViewAPIProps['default_field_definitions'];
  }) {
    this.inventory_field_definitions = inventory_field_definitions;
    this.available_result_columns = (
      Object.keys(available_result_columns) as FieldType[]
    ).map((field_type) => ({
      id: field_type,
      label: field_type,
      children: available_result_columns[field_type].map((result_column) => {
        const field_definition = inventory_field_definitions[field_type].find(
          (fd) => fd.id == result_column.id,
        );
        return { ...result_column, name: field_definition.name };
      }),
    }));

    this.totalCount = total_count;
    this.available_filters = available_filters;
    this.processSearchedInventoryEntries(initial_entries, false);
    this.queryFilters = saved_filters.map((val) => {
      return new QueryFilter(val);
    });
    this.custom_field_definitions = custom_field_definitions;
    this.all_units = all_units;
    this.user_structure_masked = user_structure_masked;
    this.vault_name_map = vault_name_map;
    this.decrementLoadingCount();
    this.setup = true;
  }

  processInventorySearchEntries(samples: Sample[]): ProcessedInventoryEntries {
    const processedEntries = samples.map(
      (sample): ProcessedInventoryEntries[number] => {
        return {
          ...sample.batch,
          ...sample,
          // gotta make sure these don't get overridden by batch or event
          name: sample.name,
          id: sample.id,
          batch_id: sample.batch.id,
          batch_name: sample.batch.name,
          sample_created_date: sample.created_at,
          sample_created_by_user_full_name: sample.created_by_user_full_name,

          sample_updated_by_user_full_name: sample.modified_by_user_full_name,
          sample_updated_date: sample.modified_at,

          inventory_sample_fields_name_keyed: sample.inventory_sample_fields,
          inventory_sample_fields:
            InventoryStoreHelper.convertNameKeysToFieldIds(
              sample.inventory_sample_fields,
              this.inventory_sample_field_definitions,
            ),
          inventory_event_fields: sample.inventory_events[0]
            ? InventoryStoreHelper.convertNameKeysToFieldIds(
              sample.inventory_events[0].inventory_event_fields,
              this.inventory_event_field_definitions,
            )
            : undefined,
          inventory_events: sample.inventory_events.map(
            (
              event,
            ): ProcessedInventoryEntries[number]['inventory_events'][number] => {
              return {
                event_id: event.id,
                event_created_by_user_id: event.created_by_user_id,
                event_created_by_user_full_name:
                  event.created_by_user_full_name,
                event_created_date: event.created_at,
                event_modified_date: event.modified_at,
                event_modified_by_user_full_name:
                  event.modified_by_user_full_name,
                inventory_event_fields_name_keyed: event.inventory_event_fields,
                inventory_event_fields:
                  InventoryStoreHelper.convertNameKeysToFieldIds(
                    event.inventory_event_fields,
                    this.inventory_event_field_definitions,
                  ),
              };
            },
          ),
        };
      },
    );
    return processedEntries;
  }

  removeError(errorIndex: number) {
    this.errors = this.errors.filter((val) => val.id !== errorIndex);
  }

  handleError(error) {
    if (axios.isAxiosError<string, string>(error)) {
      cancelBroadcastError(error);
      const errorText =
        ellipsizeString(error.response.data, 50) ?? 'An unknown error occurred';
      const id = this.errors.length;
      this.errors.push({
        id,
        label: errorText,
        messageType: MessageType.Error,
        rightButton: {
          label: 'Dismiss',
          onClick: () => {
            this.removeError(id);
          },
        },
      });
      return;
    }

    if (typeof error == 'string') {
      const id = this.errors.length;
      this.errors.push({
        id,
        label: error,
        messageType: MessageType.Error,
        rightButton: {
          label: 'Dismiss',
          onClick: () => {
            this.removeError(id);
          },
        },
      });
    }
  }

  get inventoryFieldDefinitions() {
    return buildFieldDefinitions(this.inventory_field_definitions);
  }

  get availableFilters() {
    return buildFieldDefinitions(this.available_filters);
  }

  addQueryFilter = () => {
    this.queryFilters = [...this.queryFilters, null];
  };

  updateQueryText = ({ text }: Pick<TSearchQuery, 'text'>) => {
    this.queryText = text || null;
  };

  updateQueryMRV = ({ mrv }: Pick<TSearchQuery, 'mrv'>) => {
    this.queryMRV = mrv || null;
  };

  updateQueryFilter = ({
    filter,
    index,
  }: {
    filter: TInventoryFilter;
    index: number;
  }) => {
    // spread here to update pointer
    const mutableFilters = [...this.queryFilters];
    mutableFilters[index] = new QueryFilter(filter);
    this.queryFilters = mutableFilters;
  };

  resetSearchQuery = () => {
    this.resetQueryFilters();
    this.updateQueryText({ text: undefined });
    this.updateQueryMRV({ mrv: undefined });
  };

  removeQueryFilter = (index) => {
    this.queryFilters = this.queryFilters.filter((_, ii) => ii !== index);
  };

  setDialogExportOpen = (open: boolean) => {
    this.exportDialogOpen = open;
  };

  submitExport = async (options: InventoryExportOptions) => {
    const emptiedQuery = Object.fromEntries(
      Object.entries(this.serializedSearchQuery).filter(([, val]) => {
        return val !== '' && val !== null;
      }),
    );
    CDD.Export.submit(
      this.inventory_entries_export_url,
      this.inventory_entries_export_progress_url,
      { ...emptiedQuery, ...options },
    );
  };

  resetQueryFilters = () => {
    this.queryFilters = [];
    this.submitQuery();
  };

  getNextPage = async () => {
    this.searchPage = this.searchPage + 1;
    await this.submitQuery({
      appending: true,
      onError: () =>
        // if queries error out and we're trying to get the next page, without this
        // the modal would freeze the position on the page and keep sending queries & opening new modals forever
        window.scrollTo(0, 0),
    }).catch(() => {
      // if submit fails then drop searchPage down again
      this.searchPage = this.searchPage - 1;
    });
  };

  submitNewQuery = async () => {
    this.searchPage = 0;
    this.submitQuery();
  };

  private submitQuery = async (
    { appending, onError }: { appending?: boolean; onError?: () => void } = {
      appending: false,
    },
  ) => {
    this.incrementLoadingCount();
    try {
      const { highlights, inventoryEntries, totalCount } =
        await this.sendSearchQuery();
      this.processSearchedInventoryEntries(inventoryEntries, false, appending);
      this.searchHighlights = highlights;
      this.totalCount = totalCount;
    } catch (error) {
      onError?.();
      this.handleError(error);
    } finally {
      this.decrementLoadingCount();
    }
  };

  processSearchedInventoryEntries(
    inventoryEntries: Sample[],
    sampleLimitHit: boolean,
    appending = false,
  ) {
    const processed = this.processInventorySearchEntries(inventoryEntries);
    if (appending) {
      this.inventoryEntries = [...this.inventoryEntries, ...processed];
    } else {
      this.inventoryEntries = processed;
    }

    this.sampleLimitHit = sampleLimitHit;
  }

  handleEditInventory(entry) {
    const currentlyEditingChildIndex = entry.childIndex;

    const inventory_event_fields = entry.row.inventory_events[0]
      ? InventoryStoreHelper.convertNameKeysToFieldIds(
        entry.row.inventory_events[0].inventory_event_fields,
        this.inventory_event_field_definitions,
      )
      : undefined;

    const editing: Partial<ProcessedInventoryEntries[number]> = deepClone({
      ...entry.row,
      inventory_event_fields,
    });

    let event: EditInventoryEvent | null = null;
    if (currentlyEditingChildIndex !== null) {
      event = InventoryStoreHelper.convertEntryToInventoryEvent(
        // Child Index + 1 because inventory events includes the root index
        editing.inventory_events[currentlyEditingChildIndex + 1],
        editing.id,
        this.inventory_event_field_definitions,
      );
    }

    const sample: EditSample = InventoryStoreHelper.convertToInventorySample(
      editing,
      this.inventory_event_field_definitions,
    );

    this.root.sampleDataStore.updateSamplesAndEvents([sample]);

    if (event) {
      this.root.editInventoryStore.handleEditEvent(
        event as InventoryEvent,
        sample,
      );
    } else {
      this.root.editInventoryStore.handleEditSample(sample, true);
    }
  }

  handleCancelEditInventory() {
    this.root.editInventoryStore.handleCancelEdit();
  }

  refreshDataAndCloseDialog() {
    this.submitQuery().then(() => {
      this.handleCancelEditInventory();
    });
  }

  handleSubmit = () => {
    switch (this.root.editInventoryStore.currentlyEditingValue.type) {
      case 'EDIT_SINGLE_USE_SAMPLE':
      case 'EDIT_SAMPLE':
      case 'NEW_SAMPLE':
      case 'EDIT_SAMPLE_WITH_FIRST_EVENT':
        this.handleSubmitEditSample();
        break;

      default:
        this.handleSubmitEditEvent();
        break;
    }
  };

  handleSubmitEditSample() {
    const {
      currentlyEditingValue: { sample },
    } = this.root.editInventoryStore;
    InventorySampleService.handleSubmitEditSample({
      sample,
      moleculeId: sample.batch.molecule_id,
      on409Error: async () => {
        this.handleError('409 Error');
      },
      onGenericError: async (error) => {
        this.handleError(`Request Error: ${error}`);
      },
      onSubmitSuccess: this.refreshDataAndCloseDialog,
    });
  }

  handleSubmitEditEvent() {
    const {
      currentlyEditingValue: { sample, event },
    } = this.root.editInventoryStore;

    InventorySampleService.handleSubmitEditEvent({
      on409Error: async () => {
        this.handleError('409 Error');
      },
      onGenericError: async (error) => {
        this.handleError(`Request Error: ${error}`);
      },
      onSubmitSuccess: this.refreshDataAndCloseDialog,
      moleculeId: sample.batch.molecule_id,
      event,
    });
  }

  handleDeleteSample() {
    const {
      currentlyEditingValue: { sample },
    } = this.root.editInventoryStore;

    InventorySampleService.handleDeleteSample({
      moleculeId: sample.batch.molecule_id,
      sample,
      onAfterDelete: this.refreshDataAndCloseDialog,
    });
  }

  handleDeleteEvent() {
    const {
      currentlyEditingValue: { sample, event },
    } = this.root.editInventoryStore;

    InventorySampleService.handleDeleteEvent({
      event,
      moleculeId: sample.batch.molecule_id,
      sample,

      onGenericError: async (error) => {
        this.handleError(`Request Error: ${error}`);
      },
      onAfterDelete: this.refreshDataAndCloseDialog,
    });
  }
}
