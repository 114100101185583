import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { openModalComponent, StandardDialogActions } from '@/shared/components';
import MuiTheme from '@/shared/components/MuiTheme';

type Props = {
  message: string | JSX.Element | null;
  title?: string;

  additionalElements?: JSX.Element;
  noCancelOption?: boolean;

  terminology?: {
    YES?: string;
    NO?: string;
  };

  onConfirm?: () => void;
  onCancel?: () => void;
};

export const ConfirmationDialog = (props: Props) => {
  const { title, message, terminology, additionalElements, noCancelOption, onConfirm, onCancel } =
    props;
  let dialogContent = null;
  if (message) {
    dialogContent = (
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {additionalElements}
      </DialogContent>
    );
  }
  const displayTitle = title ?? 'Confirm';
  return (
    <MuiTheme>
      <Dialog
        open={true}
        className='dialog__confirm'
      >
        {displayTitle ? <DialogTitle>{displayTitle}</DialogTitle> : null}

        <DialogContent>{dialogContent}</DialogContent>

        <StandardDialogActions
          cancelProps={
            noCancelOption
              ? undefined
              : {
                  id: 'button_no',
                  onClick: onCancel,
                  tooltip: (terminology?.NO ?? 'Cancel'),
                }
          }
          defaultButtonProps={{
            id: 'button_yes',
            onClick: onConfirm,
            label: (terminology?.YES ?? 'OK'),
          }}
        />
      </Dialog>
    </MuiTheme >
  );
};

export default function askForConfirmation(props: Props): Promise<boolean> {
  return openModalComponent(ConfirmationDialog,
    {
      ...props,
    });
}
